<template>
  <v-card class="pa-6" color="foreground">
    <loader-content v-if="loading" />

    <section v-else>
      <v-card-title class="px-0 pt-0">
        <v-btn
          v-if="!transactionPerformed"
          class="rounded-lg mr-3"
          icon
          @click="navigation(`/checkout`)"
        >
          <v-icon> mdi-arrow-left </v-icon>
        </v-btn>

        {{ getStatus(current.transaction.transactionStatusId).label }}
      </v-card-title>

      <v-card-subtitle class="text-body-1 px-0">
        Você receberá um
        <span class="secondary--text">e-mail</span> com todas as informações
        geradas por essa transação.
      </v-card-subtitle>

      <v-card
        v-if="checkMethod() && current.data.urlReceipt"
        :href="current.data.urlReceipt"
        target="_blank"
        class="rounded-lg mb-3"
        color="accent"
        flat
      >
        <v-row class="py-4 px-4 align-center justify-center" no-gutters>
          <v-icon color="primary" class="mr-3">mdi-receipt</v-icon>
          <span class="text-button primary--text">
            Visualizar Comprovante
          </span>
        </v-row>
      </v-card>

      <v-card class="rounded-lg pa-4" color="accent" flat>
        <v-list class="transparent pa-0">
          <v-list-item>
            <v-list-item-title class="secondary_text--text">
              Nº Pedido
            </v-list-item-title>

            <v-list-item-title class="text-right">
              #{{ current.transaction.orderId }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title class="secondary_text--text">
              Nome
            </v-list-item-title>

            <v-list-item-title class="text-right">
              {{ current.transaction.userName }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title class="secondary_text--text">
              Documento
            </v-list-item-title>

            <v-list-item-title class="text-right">
              {{ current.transaction.document }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title class="secondary_text--text"
              >E-mail
            </v-list-item-title>

            <v-list-item-title class="text-right">
              {{ current.transaction.email }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title class="secondary_text--text">
              Valor
            </v-list-item-title>

            <v-list-item-title class="text-right">
              R$
              {{ moneyMask(current.transaction.convertedValue) }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title class="secondary_text--text">
              Método de pagamento
            </v-list-item-title>

            <v-list-item-title class="text-right">
              {{ getMethod(current.transaction.paymentMethod) }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title class="secondary_text--text">
              Data e hora
            </v-list-item-title>

            <v-list-item-title class="text-right">
              {{ formatDate(current.transaction.createdAt) }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title class="secondary_text--text">
              Status
            </v-list-item-title>

            <v-list-item-title class="text-right">
              <v-chip
                :color="
                  getStatus(current.transaction.transactionStatusId).color
                "
                label
              >
                {{ getStatus(current.transaction.transactionStatusId).label }}
              </v-chip>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>

      <v-btn
        v-if="jwtDecoded.transaction.redirectURL"
        :href="jwtDecoded.transaction.redirectURL"
        color="primary"
        class="mt-6"
        block
        large
      >
        Retornar para o site
      </v-btn>
    </section>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { getTransaction } from "@/services/checkout";
import { formatDate, moneyMask } from "@/utils";

export default {
  data() {
    return {
      loading: true,
    };
  },

  beforeMount() {
    const param = this.$route.params.newTransaction;

    if (param) this.getData(param);
    else this.loading = false;
  },

  computed: {
    ...mapState(["jwtDecoded", "current", "transactionPerformed"]),
  },

  methods: {
    ...mapActions(["setCurrent"]),

    async getData(value) {
      try {
        this.loading = true;

        const payload = {
          ref: value,
          document: this.jwtDecoded.customer.document,
        };

        await getTransaction(payload).then((res) => {
          this.setCurrent(res.body); // commit on storage
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // return label of method
    getMethod(value) {
      switch (value) {
        case "TRANSFER":
          return "Transferência Bancária";
        case "CARD":
          return "Cartão de Crédito";
        case "BOLETO":
          return "Boleto Bancário";
        case "PIX":
          return "Pix";
        case "PICPAY":
          return "PicPay";
        default:
          break;
      }
    },

    // return label of status
    getStatus(value) {
      switch (value) {
        case 1:
          return {
            label: "Pagamento em Análise",
            color: "amber",
          };
        case 2:
          return {
            label: "Pagamento Aprovado",
            color: "green",
          };
        case 3:
          return {
            label: "Pagamento Recusado",
            color: "red",
          };
        case 4:
          return {
            label: "Pagamento Cancelado",
            color: "grey",
          };
        case 5:
          return {
            label: "Pagamento Estornado",
            color: "purple",
          };
        default:
          break;
      }
    },

    // check if current method has receipt
    checkMethod() {
      const method = this.current.transaction.paymentMethod;

      if (
        method === "TRANSFER" ||
        method === "BOLETO" ||
        method === "PIX" ||
        method === "PICPAY"
      )
        return true;
      else return false;
    },

    // router push
    navigation(path) {
      this.$router.push({ path: path });
    },

    formatDate,

    moneyMask,
  },
};
</script>

<style>
</style>